input {
  caret-color: var(--ion-color-primary)
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

ion-popover.filters-popover {
  --width: 250px
}

ion-toolbar {
  --background:
    linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #FFFFFF;
}

ion-toolbar.light-toolbar {
  --background: #FFFFFF;
  color: black
}

ion-toolbar.bookpoints-toolbar,
.toolbar-container {
  height: 40px;
  --min-height: 40px;
}

ion-toolbar.bookpoints-toolbar,
.toolbar-background {
  --border-width: 0px !important
}

.leaflet-popup-content {
  margin: 5px
}

.leaflet-div-icon {
  background: transparent;
  border: 0px
}

a.leaflet-popup-close-button {
  top: 5px !important;
  right: 5px !important
}

.leaflet-popup-close-button>span {
  font-size: 30px;
}

ion-toolbar.secondary-toolbar {
  --background: var(--ion-color-secondary);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: black
}

ion-toolbar.ios input {
  background: #EEE !important
}

.toolbar-home {
  position: relative;
}

.toolbar-home-background {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  bottom: 40px;
  background:
    linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

ion-tab-bar {
  --background: #f2f3f7;
  border-radius: 20px 20px 0px 0px;
}

ion-card {
  border-radius: 20px;
  margin: 0px 8px 10px;
  box-shadow: none !important;
}

ion-input,
ion-textarea {
  border-radius: 20px;
  --padding-start: 10px !important;
}

.error-div {
  color: "danger"
}

.my-custom-class .modal-wrapper {
  --background: #222;
}

ion-modal.login-modal,
ion-modal.book-confirmation-modal {
  --width: 90%;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 20px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.login-email-modal {
  --width: 85%;
  --height: 275px;
  --border-radius: 20px;
}

ion-modal.date-modal {
  --width: 281px;
  --height: 350px;
  --border-radius: 20px;
}

ion-modal.confirmation-modal {
  --width: 300px;
  --height: 200px;
  --border-radius: 20px;
}

ion-modal.sync-borrow-modal {
  --width: 90%;
  --height: 90vw;
  --border-radius: 20px;
}

ion-modal.sync-borrow-modal video {
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 650px) {

  ion-modal.login-email-modal,
  ion-modal.login-modal {
    --width: 553px;
  }

  ion-modal.book-confirmation-modal {
    --width: 585px;
  }
}

ion-tabs {
  max-width: 650px;
  margin: auto;
}


ion-toolbar.toolbar-background {
  --border-bottom-left-radius: 20px !important;
  --border-bottom-right-radius: 20px;
}

ion-avatar>img {
  width: 100% !important;
  height: 100% !important;
  max-width: 200px !important;
  max-height: 200px !important;
}

ion-searchbar input {
  border-radius: 20px !important;
  --background: #eaeaea;
}

ion-chip.light-chip {
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);
}

ion-chip.primary-chip {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

ion-back-button {
  margin-left: 5px
}

video {
  width: "100%";
  border-radius: 20px
}

.ion-padding-half {
  padding: 8px !important;
}

.swiper-slide {
  text-align: left;
}

.header-md::after {
  background-image: none !important;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: 10px;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  padding-inline-start: 35px;
}

.swiper-pagination-bullet {
  background-color: #FFF !important;
}

.swiper-pagination-bullet-active {
  background-color: #444 !important;
}

/* hide play button on video tag */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.safe-area-footer {
  padding-bottom: env(safe-area-inset-bottom);
}

.safe-area-header {
  padding-top: calc(env(safe-area-inset-top) + 40px);
}

.banner-tutorial {
  transform: translateY(-50px)
}

.leaflet-container {
  width: 100%;
  height: 100%
}

.scan-box {
  border: 1px solid gray;
  box-shadow: 0 0 0 100vmax rgba(255, 255, 255);
  content: '';
  display: block;
  left: 50%;
  height: 48vh;
  position: absolute;
  top: 44%;
  transform: translate(-50%, -50%);
  width: 92%;
  border-radius: 20px;
  overflow: hidden;
}

.scan-info-top {
  margin: 0px;
  position: absolute;
  top: 3%;
  width: 92%;
  height: 20px;
  z-index: 11;
  text-align: center;
}

.scan-info-bottom {
  margin: 0px;
  position: absolute;
  bottom: 23%;
  width: 92%;
  height: 50px;
  z-index: 11;
  text-align: center;
}

.scan-box-delimiters {
  height: 55%;
  width: 85%;
  position: absolute;
  top: 32%;
  left: 18%
}

.scan-box-delimiters:before,
.scan-box-delimiters:after,
.scan-box-delimiters em:after,
.scan-box-delimiters em:before {
  border-color: var(--ion-color-primary);
  content: "";
  position: absolute;
  top: 32%;
  left: 60%;
  width: 40px;
  height: 30%;
  border-style: solid;
  border-width: 0px;
}

.scan-box-delimiters:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 5px 0 0 0;
}

.scan-box-delimiters:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 5px 0 0;
}

.scan-box-delimiters em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 5px;
}

.scan-box-delimiters em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 5px 0;
}

.toast-wrapper{
  bottom: 60px !important
}